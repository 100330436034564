// Customizable Area Start
import React from 'react';
import {
    Container,
    Box,
    Button,
    Typography,
    Grid
  } from "@mui/material";
  import assets from "../src/assets";

import { Dimensions } from "react-native";
import {
    createTheme,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
  } from "@mui/material/styles";
import EmailAccountRegistrationController, {
    Props,
    configJSON,
  } from "./EmailAccountRegistrationController";

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End  
export default class WelcomeSignup extends EmailAccountRegistrationController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      // Customizable Area Start
      let barWidth = Dimensions.get("window").width - 45;
      barWidth = barWidth > 550 ? 540 : barWidth;
      return (
        <StyledEngineProvider injectFirst>
           <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            display: "flex",
            height: "100vh",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
  sx={{
    flex: 1,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    borderRight: { md: "1px solid #e0e0e0" },
    height: "100vh", 
  }}
>
  <Typography
    variant="body2"
    sx={{
      alignSelf: "flex-start",
      marginBottom: "24px",
      cursor: "pointer",
      color: "#007bff",
      fontWeight: 500,
    }}
  >
    ← Back to the website
  </Typography>

  <Typography
    variant="h4"
    sx={{
      marginBottom: "16px",
      color: "#212529",
      fontWeight: "bold",
      textAlign: "center",
    }}
  >
    Register or Log in
  </Typography>

  <Typography
    variant="body1"
    sx={{
      marginBottom: "24px",
      color: "#6c757d",
      textAlign: "center",
      fontSize: "16px",
    }}
  >
    I’m new to Afya Match
  </Typography>

  <Button
    data-test-id="signUp"
    variant="contained"
    sx={{
      marginBottom: "16px",
      width: "100%",
      maxWidth: "320px",
      height: "48px",
      backgroundColor: "#0056b3",
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      textTransform: "none",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#004099",
      },
    }}
  >
    Sign Up
  </Button>

  <Typography
    variant="body1"
    sx={{
      marginBottom: "16px",
      color: "#6c757d",
      textAlign: "center",
      fontSize: "16px",
    }}
  >
    I already have an account
  </Typography>

  <Button
    data-test-id="login"
    variant="contained"
    sx={{
      width: "100%",
      maxWidth: "320px",
      height: "48px",
      backgroundColor: "#28a745",
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      textTransform: "none",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#218838",
      },
    }}
  >
    Login
  </Button>
</Box>


          <Box
  sx={{
    flex: 1,
    backgroundColor: "#eaf4fa", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px 20px",
    position: "relative",
  }}
>
  <Box
    component="img"
    src={assets.logo} 
    alt="Afya Match Logo"
    sx={{
      marginBottom: "30px",
      width: "150px",
    }}
  />

  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "20px",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    }}
  >
    <Box
      component="img"
      src={assets.Rectangle7} 
      alt="Central Doctor"
      sx={{
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        objectFit: "cover",
        border: "4px solid #fff",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        gridColumn: "2 / 3", 
      }}
    />

    <Box
      component="img"
      src={assets.Rectangle11}
      alt="Top Doctor"
      sx={{
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        objectFit: "cover",
        border: "3px solid #fff",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        position: "absolute",
        top: "-40px",
        left: "calc(50% - 40px)",
      }}
    />

    {["doctor3", "doctor4", "doctor5", "doctor6"].map((image, index) => (
      <Box
        key={index}
        component="img"
        src={assets.Rectangle10} 
        alt={`Doctor ${index + 3}`}
        sx={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
          border: "3px solid #fff",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      />
    ))}
  </Box>

  <Typography
    variant="h6"
    sx={{
      marginTop: "40px",
      color: "#212529",
      textAlign: "center",
      fontSize: "1.1rem",
      lineHeight: "1.5",
    }}
  >
    Quick and easy access to trusted healthcare providers
  </Typography>
</Box>


        </Container>
      </ThemeProvider>
        </StyledEngineProvider>
      );
      // Customizable Area End
    }
  }
const webStyle = {
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
   
  };