// Customizable Area Start
import React from 'react';
import {
    Container,
    Box,
    Button,
    Typography,
    CssBaseline,
    Paper
  } from "@mui/material";
import assets from "../src/assets";

import {
    createTheme,
    ThemeProvider,
    styled,
  } from "@mui/material/styles";
  
import LandingPageController, {
    Props,
    configJSON,
  } from "./LandingPageController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
      light: "#4DD8B5",
      dark: "#007A5E",
    },
    text: {
      primary: "#27292A",
      secondary: "#27292A",
    },
    background: {
      default: "#FFFFFF",
      paper: "#B6D4E3",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "64px",
      fontWeight: 500,
      lineHeight: 1.2,
      textTransform: "uppercase",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "36px",
      },
    },
    h2: {
      fontSize: "40px",
      fontWeight: 600,
      lineHeight: 1.3,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "22px",
      },
    },
    h3: {
      fontSize: "24px",
      lineHeight: 1.4,
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    h4: {
      fontSize: "20px",
      lineHeight: 1.15,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    h5: {
      fontSize: "20px",
      lineHeight: 1,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body1: {
      fontSize: "16px",
      lineHeight: 1.25,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
      color: "#27292A"
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "700",
          padding: "10px 18px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "&:disabled": {
            backgroundColor: "#A8D5B4",
            color: "#8a8a8a",
          },
          "@media (max-width: 600px)": {
            fontSize: "14px",
            padding: "8px 16px",
          },
        },
        contained: {
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#23894F",
          },
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        size: "medium",
      },
    },
  },
});

const benefitsContent = [
  {
    title: configJSON.benefitsPatientsTitle,
    photoUrl: assets.benefitsGirl,
    content: [
      configJSON.benefitsCheck1,
      configJSON.benefitsCheck2,
      configJSON.benefitsCheck3,
    ]
  },
  {
    title: configJSON.benefitsClinicsTitle,
    photoUrl: assets.benefitsBoy,
    content: [
      configJSON.benefitsCheck4,
      configJSON.benefitsCheck5,
      configJSON.benefitsCheck6,
    ]
  }
]

const doctorImages = [
  assets.doctor1,
  assets.doctor2,
  assets.doctor3,
  assets.doctor4,
]
  // Customizable Area End  
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      // Customizable Area Start
      return (
        <>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <BoxWrapper>
              <section className="main__hero__section">
                <img className="hero__background" src={assets.heroBackground} alt="hero-background" />
                <img className="curve__background" src={assets.bgCurve} alt="banner" />

                <nav className="nav__section">
                  <Container>
                    <Box className="navbar__wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                      <img className="branding__logo" src={assets.logo} alt="branding" />

                      <Box className="account__wrapper">
                        <img className="profile__icon" src={assets.personIcon} alt="icon" />
                        <Typography className="action__label" variant="body1">
                          {configJSON.signUpLogInLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Container>
                </nav>

                <Box
                  className="content__wrapper"
                  sx={{
                    pt: { xs: 15, md: 8 },
                    pb: { xs: 20, md: 20 },
                  }}
                >
                  <Typography className="hero__title" variant="h1" dangerouslySetInnerHTML={{ __html: configJSON.heroSectionTitle }}></Typography>
                  <Typography className="hero__tagline" variant="h4" sx={{ m: { xs: 1, sm: 2 } }}>
                    {configJSON.heroSectionContent}
                  </Typography>

                  <Button variant="contained" sx={{ mt: { xs: 1.5, md: 2.5 } }}>
                    {configJSON.heroButtonLabel}
                  </Button>
                </Box>

                <Container>
                  <Box className="doctor__photos__wrapper">
                    {doctorImages.map((imageUrl) => (
                      <Box className="photo__wrapper">
                        <img className="doctor__photo" src={imageUrl} alt="doctor" />
                      </Box>
                    ))}
                  </Box>

                  <Typography
                    variant="h2"
                    className="hero__main__content"
                    sx={{
                      mt: { xs: 5, md: 18 },
                      mb: { xs: 5, md: 16 },
                    }}
                    dangerouslySetInnerHTML={{ __html: configJSON.heroSectionTagLine }}
                  ></Typography>
                </Container>
              </section>

              <Box className="feature__section" sx={{ pt: { xs: 8, md: 13 }, pb: { xs: 8, md: 13 } }}>
                <Container>
                  <Box className="feature__wrapper">
                    <Box className="logo__wrapper">
                      <img className="logo__large" src={assets.logoLarge} alt="logo" />
                    </Box>

                    <Box className="feature__card__wrapper">
                      <Box className="feature__card">
                        <Box>
                          <img className="feature__card__icon" src={assets.iconTime} alt="icon-time" />
                        </Box>
                        <Box className="feature__card__content">
                          <Typography className="feature__title">{configJSON.featureTitle1}</Typography>
                          <Typography className="feature__description">{configJSON.featureDescription1}</Typography>
                        </Box>
                      </Box>

                      <Box className="feature__card">
                        <Box>
                          <img className="feature__card__icon" src={assets.iconCalendar} alt="icon-time" />
                        </Box>
                        <Box className="feature__card__content">
                          <Typography className="feature__title" dangerouslySetInnerHTML={{ __html: configJSON.featureTitle3 }}></Typography>
                          <Typography className="feature__description">{configJSON.featureDescription3}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="feature__card__wrapper">
                      <Box className="feature__card">
                        <Box>
                          <img className="feature__card__icon" src={assets.iconSecurity} alt="icon-time" />
                        </Box>
                        <Box className="feature__card__content">
                          <Typography className="feature__title">{configJSON.featureTitle2}</Typography>
                          <Typography className="feature__description">{configJSON.featureDescription2}</Typography>
                        </Box>
                      </Box>

                      <Box className="feature__card">
                        <Box>
                          <img className="feature__card__icon" src={assets.iconPrivacy} alt="icon-time" />
                        </Box>
                        <Box className="feature__card__content">
                          <Typography className="feature__title" dangerouslySetInnerHTML={{ __html: configJSON.featureTitle4 }}></Typography>
                          <Typography className="feature__description">{configJSON.featureDescription4}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>

              <Paper className="benefits__section" sx={{ pt: { xs: 8, md: 13 }, pb: { xs: 8, md: 13 } }}>
                <Container>
                  <Typography variant="h2" className="benefits__title" sx={{ mb: { xs: 1, md: 2 } }}>
                    {configJSON.benefitsTitleLabel}
                  </Typography>

                  {benefitsContent.map((content) => (
                    <Box className="benefits__card__wrapper" sx={{ mt: { xs: 4, md: 10 } }}>
                      <Box className="benefits__content" sx={{ gap: { xs: "12px", sm: "24px", md: "40px" } }}>
                        <Typography className="card__title" variant="h3">
                          {content.title}
                        </Typography>

                        {content.content.map((title) => (
                          <Box className="card__content">
                            <img className="icon__checkbox" src={assets.iconCheckbox} alt="icon" />
                            <Typography className="benefits__description" variant="h4">
                              {title}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <img className="benefits__photo" src={content.photoUrl} alt="girl_photo" />
                    </Box>
                  ))}
                </Container>
              </Paper>

              <Box className="action__section" sx={{ pt: { xs: 8, md: 13 }, pb: { xs: 8, md: 13 } }}>
                <Container>
                  <Typography className="hero__title" variant="h2">
                    {configJSON.findDoctorTitle}
                  </Typography>
                  <Typography className="hero__tagline" variant="h3" sx={{ m: { xs: 1, sm: 2 } }}>
                    {configJSON.findDoctorDescription}
                  </Typography>

                  <Button variant="contained" sx={{ mt: { xs: 1.5, md: 2.5 } }}>
                    {configJSON.heroButtonLabel}
                  </Button>
                </Container>
              </Box>

              <Box className="footer" sx={{ pt: { xs: 3, md: 6 }, pb: { xs: 3, md: 6 } }}>
                <Container>
                  <Box className="footer__wrapper">
                    <Box display="flex" alignItems="center" sx={{ mb: { xs: 2, md: 0 } }}>
                      <img className="footer__logo" src={assets.footerLogo} alt="footer-logo" />

                      <Typography className="footer__tag" variant="h2" sx={{ ml: { xs: 2, md: 4 } }}>
                        {configJSON.alreadyHaveAccount}
                      </Typography>
                    </Box>

                    <Button variant="contained" className="btn__login">
                      {configJSON.btnLoginLabel}
                    </Button>
                  </Box>
                </Container>
              </Box>
            </BoxWrapper>
          </ThemeProvider>
        </>
      );
      // Customizable Area End
    }
  }

// Customizable Area Start
const BoxWrapper = styled(Box)({
  "& .main__hero__section": {
    position: "relative",
    overflow: "hidden",
    "& .hero__background": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: -2,
    },
    "& .curve__background": {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      objectFit: "fill",
      width: "100%",
      height: "50%",
      maxHeight: "575px",
    },
    "& .nav__section": {
      borderBottom: "solid 1px #BAD5EF",
      "& .navbar__wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .branding__logo": {
          width: "76px",
          aspectRatio: "1.58",
        },
        "& .account__wrapper": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          "& .profile__icon": {
            width: "16px",
            height: "16px",
          },
          "& .action__label": {
            color: "#2EAB65",
            fontWeight: 600,
          },
        },
      },
    },
    "& .content__wrapper": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& .hero__title": {
        maxWidth: "580px",
        textWrap: "wrap",
        textAlign: "center",
        "& .branding": {
          fontWeight: 800,
          color: "#2EAB65",
        },
      },
      "& .hero__tagline": {
        textAlign: "center",
        maxWidth: "625px",
        textWrap: "wrap",
      },
    },
    "& .hero__main__content": {
      fontWeight: "unset",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "36px",
    },
    "& .doctor__photos__wrapper": {
      display: "flex",
      gap: "24px",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& .photo__wrapper": {
        flex: "1 1 calc(25% - 24px)",
        maxHeight: "300px",
        display: "flex",
        alignItmes: "center",
        justifyContent: "center",
        border: "solid 4px #FFF",
        borderRadius: "10px",
        overflow: "hidden",
        background: "linear-gradient(0deg, rgba(161,195,219,1) 0%, rgba(231,246,247,1) 100%)",
        "& .doctor__photo": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        "&:nth-child(odd)": {
          marginTop: "32px",
        },
        "&:nth-child(even)": {
          marginBottom: "32px",
        },
      },
    },
  },
  "& .feature__section": {
    backgroundColor: "#D2EFF2",
    "& .feature__wrapper": {
      display: "flex",
      gap: "25px",
      "& .logo__wrapper": {
        border: "solid 4px #FFF",
        borderRadius: "32px",
        backgroundColor: "#C4EBF0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        maxWidth: "350px",
      },
      "& .feature__card__wrapper": {
        flex: 1,
        display: "flex",
        gap: "44px",
        flexDirection: "column",
        "& .feature__card": {
          display: "flex",
          gap: "24px",
          "& .feature__card__content": {
            "& .feature__title": {
              fontWeight: 800,
              textTransform: "uppercase",
              marginBottom: "8px",
            },
          },
        },
      },
    },
  },
  "& .benefits__section": {
    "& .benefits__title": {
      textTransform: "uppercase",
    },
    "& .benefits__card__wrapper": {
      display: "flex",
      justifyContent: "space-between",
      "& .benefits__content": {
        display: "flex",
        flexDirection: "column",
        "& .card__title": {
          textTransform: "uppercase",
          fontWeight: 600,
        },
        "& .card__content": {
          display: "flex",
          gap: "12px",
          alignItems: "center",
          "& .benefits__description": {
            maxWidth: "360px",
          },
        },
      },
      "& .benefits__photo": {
        maxWidth: "510px",
      },
    },
  },
  "& .action__section": {
    backgroundColor: "#D2EFF2",
    textAlign: "center",
  },
  "& .footer": {
    backgroundColor: "#263588",
    "& .footer__wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .footer__logo": {
        aspectRatio: 1.8,
      },
      "& .footer__tag": {
        textTransform: "uppercase",
        color: "#FFF !important",
      },
      "& .btn__login": {
        width: "200px",
      },
    },
  },
  "@media(max-width: 1000px)": {
    "& .benefits__section": {
      "& .benefits__card__wrapper": {
        flexDirection: "column !important",
        justifyContent: "unset",
        "& .benefits__photo": {
          marginTop: "12px",
          maxWidth: "unset",
        },
        "& .card__content": {
          "& .icon__checkbox": {
            width: "24px !important",
            height: "24px !important",
          },
          "& .benefits__description": {
            maxWidth: "unset !important",
          },
        },
      },
    },
    "& .main__hero__section": {
      "& .doctor__photos__wrapper": {
        "& .photo__wrapper": {
          flex: "1 1 calc(50% - 24px)",
          "&:nth-child(odd)": {
            marginTop: "0",
          },
          "&:nth-child(even)": {
            marginBottom: "0",
          },
        },
      },
    },
  },
  "@media(max-width: 900px)": {
    "& .feature__section": {
      "& .feature__wrapper": {
        flexDirection: "column",
        "& .logo__wrapper": {
          maxWidth: "unset",
          borderRadius: "16px",
          border: "solid 2px #FFF",
          "& .logo__large": {
            width: "130px",
          },
        },
        "& .feature__card__wrapper": {
          gap: "16px",
          "& .feature__card": {
            gap: "16px",
            "& .feature__card__icon": {
              width: "28px",
              height: "28px",
            },
          },
        },
      },
    },
  },
  "@media (max-width: 600px)": {
    "& .hero__background": {},
    "& .navbar__wrapper": {
      "& .branding__logo": {
        width: "65px !important",
      },
      "& .account__wrapper": {
        "& .profile__icon": {
          width: "12px !important",
          height: "12px !important",
        },
      },
    },
    "& .hero__main__content": {
      fontSize: "22px !important",
    },
    "& .benefits__section": {
      "& .benefits__card__wrapper": {
        flexDirection: "column !important",
        justifyContent: "unset",
      },
    },
    "& .footer": {
      "& .footer__wrapper": {
        flexDirection: "column !important",
      },
      "& .footer__tag": {
        fontSize: "14px !important",
      },
      "& .footer__logo": {
        width: "70px !important",
      },
    },
  },
});
// Customizable Area End