export const logo = require("../assets/logo.png");
export const Rectangle5 = require("../assets/Rectangle5.png");
export const Rectangle6 = require("../assets/Rectangle6.png");
export const Rectangle7 = require("../assets/Rectangle7.png");
export const Rectangle8 = require("../assets/Rectangle8.png");
export const heroBackground = require("../assets/heroBackground.png");
export const personIcon = require("../assets/person_icon.png");
export const footerLogo = require("../assets/footer_logo.png");
export const iconCheckbox = require("../assets/icon_checkbox.png");
export const benefitsGirl = require("../assets/photo_girl.png");
export const benefitsBoy = require("../assets/photo_man.png");
export const logoLarge = require("../assets/logo_large.png");
export const iconCalendar = require("../assets/icon_calendar.png");
export const iconPrivacy = require("../assets/icon_privacy.png");
export const iconSecurity = require("../assets/icon_security.png");
export const iconTime = require("../assets/icon_time.png");
export const bgCurve = require("../assets/bg_curve_banner.png");
export const doctor1 = require("../assets/doctor1.png");
export const doctor2 = require("../assets/doctor2.png");
export const doctor3 = require("../assets/doctor3.png");
export const doctor4 = require("../assets/doctor4.png");



const assets = {
    logo,
    Rectangle5,
    Rectangle6,
    Rectangle7,
    Rectangle8,
    heroBackground,
    personIcon,
    footerLogo,
    iconCheckbox,
    benefitsGirl,
    benefitsBoy,
    logoLarge,
    iconCalendar,
    iconSecurity,
    iconPrivacy,
    iconTime,
    bgCurve,
    doctor1,
    doctor2,
    doctor3,
    doctor4
  };
  
export default assets;

