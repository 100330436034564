
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Rectangle7 = require("../assets/Rectangle7.png");
export const Rectangle8 = require("../assets/Rectangle8.png");
export const logo = require("../assets/logo.png");
export const Rectangle9 = require("../assets/Rectangle8.png");
export const Rectangle10 = require("../assets/Rectangle10.png");
export const Rectangle11 = require("../assets/Rectangle11.png");
export const clock_loader_20 = require("../assets/clock_loader_20.png");

const assets = {
    Rectangle7,
    Rectangle8,
    logo,
    imgPasswordVisible,
    imgPasswordInVisible,
    Rectangle9,
    Rectangle10,
    Rectangle11,
    clock_loader_20
  };
  
  export default assets;

