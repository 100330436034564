// Customizable Area Start
import React from 'react';
import {
    Box,
    Button,
    Typography,
    Grid
  } from "@mui/material";

import { Dimensions } from "react-native";
import {
    createTheme,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
  } from "@mui/material/styles";
import assets from "../src/assets";
import EmailAccountRegistrationController, {
    Props,
    configJSON,
  } from "./EmailAccountRegistrationController";

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End  
export default class WelcomeSignupRole extends EmailAccountRegistrationController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      // Customizable Area Start
      let barWidth = Dimensions.get("window").width - 45;
      barWidth = barWidth > 550 ? 540 : barWidth;
      return (
        <StyledEngineProvider injectFirst>
             <Grid container sx={{ height: "100vh", backgroundColor: "#F8FAFB" }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          px: { xs: 3, md: 8 },
        }}
      >
        <Button
          data-test-id="btnBack"
          variant="text"
          sx={{
            alignSelf: "flex-start",
            color: "#6C757D",
            fontSize: "14px",
            fontWeight: 500,
            mb: 3,
          }}
        >
          &#8592; Back
        </Button>
        <Typography
          variant="h5"
          sx={{
            fontSize: "24px",
            fontWeight: 500,
            color: "#323441",
            mb: 4,
          }}
        >
          You would like to register as a..
        </Typography>
        <Button
          data-test-id="btnRole1"
          variant="outlined"
          sx={{
            borderColor: "#2EAB65",
            color: "#2EAB65",
            fontWeight: "bold",
            borderRadius: "8px",
            py: 1.5,
            mb: 2,
            "&:hover": {
              borderColor: "#2EAB65",
              backgroundColor: "#F1FAF5",
            },
          }}
        >
          Healthcare Professional
        </Button>
        <Button
          data-test-id="btnRole2"
          variant="outlined"
          sx={{
            borderColor: "#2EAB65",
            color: "#2EAB65",
            fontWeight: "bold",
            borderRadius: "8px",
            py: 1.5,
            "&:hover": {
              borderColor: "#2EAB65",
              backgroundColor: "#F1FAF5",
            },
          }}
        >
          Healthcare Facility
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#EAF3FB",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: { xs: 3, md: 8 },
        }}
      >
        <Box
          component="img"
          src={assets.logo} 
          alt="Logo"
          sx={{
            width: "150px",
            height: "auto",
            mb: 5,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            mb: 4,
          }}
        >
          <Box
            component="img"
            src={assets.Rectangle10}
            alt="Doctor"
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              position: "absolute",
              top: "-30px",
              left: "-50px",
            }}
          />
          <Box
            component="img"
            src={assets.Rectangle11} 
            alt="Nurse"
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              position: "absolute",
              top: "-30px",
              right: "-50px",
            }}
          />
          <Box
            component="img"
            src={assets.Rectangle7} 
            alt="Handshake"
            sx={{
              width: "140px",
              height: "140px",
              borderRadius: "50%",
              border: "5px solid #FFFFFF",
            }}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: "#323441",
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          Find doctors you can trust, instantly
        </Typography>
      </Grid>
    </Grid>
        </StyledEngineProvider>
      );
      // Customizable Area End
    }
  }
const webStyle = {
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
   
  };