Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.signUpLogInLabel = "Sign Up / Log In";
exports.heroSectionTitle = "Your Guide<br /> To <span class='branding'>Trusted</span> care";
exports.heroSectionContent = "Your all-in-one platform for finding doctors, booking appointments, and securing temporary specialists for clinics in need";
exports.heroSectionTagLine = "<b>Say goodbye to the hassle of searching and booking healthcare.</b> Our app brings clinics, specialists, and patients together for a seamless care experience.";
exports.heroButtonLabel = "Find your doctor now";
exports.findDoctorTitle = "Get Started with Trusted Healthcare Today!";
exports.findDoctorDescription = "Join thousands of patients and clinics who trust us for their healthcare needs. Sign up now and experience the convenience and reliability of modern healthcare.";
exports.alreadyHaveAccount = "Already have an account?";
exports.btnLoginLabel = "Login";
exports.benefitsTitleLabel = "Benefits for Patients And Clinics";
exports.benefitsPatientsTitle = "For Patients";
exports.benefitsCheck1 = "Quick and easy access to trusted healthcare providers";
exports.benefitsCheck2 = "Seamless booking experience with instant confirmation";
exports.benefitsCheck3 = "Secure platform to protect your health information";
exports.benefitsClinicsTitle = "For clinics";
exports.benefitsCheck4 = "Efficiently find qualified specialists to cover shifts";
exports.benefitsCheck5 = "No more gaps in patient care due to staff shortages";
exports.benefitsCheck6 = "Easy-to-use interface for clinic administrators";
exports.featureTitle1 = "Save Time with Instant Access";
exports.featureTitle2 = "Reliable and Trustworthy Care";
exports.featureTitle3 = "Convenient Booking<br/> Anytime, Anywhere";
exports.featureTitle4 = "Secure and Private<br/> for Peace of Mind";
exports.featureDescription1 = "Quickly find and book trusted doctors or specialists without long wait times or complicated searches.";
exports.featureDescription2 = "Access a vetted network of clinics and specialists you can trust for quality healthcare every time.";
exports.featureDescription3 = "Book appointments or coverage for your clinic on your schedule, from any device, wherever you are.";
exports.featureDescription4 = "Your health data is protected with top-level security, so you can focus on what matters – your health.";
// Customizable Area End